import React from 'react';
import { Grid } from '@mui/material';
import ToolsListItem from './ToolsListItem';
import { isMobile } from 'react-device-detect';
import ImageIcon from '@mui/icons-material/Image';

const TOOLS = [
    {
        key: 'aiImageGen',
        display: 'AI Image Generator',
        icon: <ImageIcon fontSize="large" />,
        route: '/aiImageGen'
    }
];

const ToolsContainer = (props) => {
    return (
        <Grid
            container
            spacing={isMobile ? 0 : 2}
            className="flex justify-center items-center"
        >
            {TOOLS.map((tool) => (
                <ToolsListItem tool={tool} />
            ))}
        </Grid>
    );
};

export default React.memo(ToolsContainer);
